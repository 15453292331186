import React, { Fragment, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MainContent from "./components/MainContent.react";
import Start from "./components/Start.react";
import Applications from "./components/Applications.react";
import Facebook from "./components/Facebook.react";
import Cutouts from "./components/Cutouts.react";
import Projects from "./components/Projects.react";
import Assets from "./components/Assets.react";
import Exports from "./components/Exports.react";
import LoginPage from "./components/LoginPage.react";
import SignupPage from "./components/SignupPage.react";
import PhotoEditorPage from "./pages/PhotoEditorPage";
import Folders from "./components/Folders.react";
import Templates from "./components/Templates.react";
import Uploaded from "./components/Uploaded.react";
import Browser from "./components/Browser.react";
import HomePage from "./pages/HomePage";
import JSONUtils from "./utils/JSONUtils";
import UserStore from "./stores/UserStore";
import { GoogleOAuthProvider } from "@react-oauth/google";
import UserActionCreators from "./actions/UserActionCreators";
import ProtectedRoute from "./utils/ProtectedRoute";
import PageNotFound from "./pages/NotFoundPage";
import SearchPage from "./pages/SearchPage";
import SearchPage2 from "./pages/SearchPage2";
import GoTop from "./components/GoTop";
import CollageMakerPage from "./pages/CollageMakerPage";
import OnlineDesignPage from "./pages/OnlineDesignPage";
import TouchupToolPage from "./pages/TouchupToolPage";
import AddTextPhotoPage from "./pages/AddTextPhotoPage";
import CustomCardPage from "./pages/CustomCardPage";
import ClipArtStickerPage from "./pages/ClipArtStickerPage";
import PhotoEffectspage from "./pages/PhotoEffectsPage";
import MemeMakerPage from "./pages/MemeMakerPage";
import FrameBordersPage from "./pages/FrameBordersPage";
import FacebookCovermakerPage from "./pages/FacebookCoverMakerPage";
import TwitterCoverMakerPage from "./pages/TwitterCoverMakerPage";
import LogoMakerPage from "./pages/LogoMakerPage";
import YoutubeCovermakerPage from "./pages/YoutubeCoverMakerPage";
import AirbrushMakeupPage from "./pages/AirbrushMakeupPage";
import BlemishRemoverToolPage from "./pages/BlemishRemoverToolPage";
import BusinessCardMakerPage from "./pages/BusinessCardMakerPage";
import ChristmasCardMakerPage from "./pages/ChristmasCardMakerPage";
import CutoutToolPage from "./pages/CutoutToolPage";
import EyeColorPage from "./pages/EyeColorPage";
import FaceEditorPage from "./pages/FaceEditorPage";
import FlyerMakerPage from "./pages/FlyerMakerPage";
import FreeStockImagePage from "./pages/FreeStockImagePage";
import GraphicDesignMakerPage from "./pages/GraphicDesignMakerPage";
import HolidayCardMakerPage from "./pages/HolidayCardMakerPage";
import LipTintChangePage from "./pages/LipTintChangePage";
import PhotoBackgroundPage from "./pages/PhotoBackgroundPage";
import PhotoCropPage from "./pages/PhotoCropPage";
import PosterMakerPage from "./pages/PosterMakerPage";
import SpeechBubblesPage from "./pages/SpeechBubblesPage";
import TeethWhiteningPage from "./pages/TeethWhiteningPage";
import ThankCardPage from "./pages/ThankCardPage";
import WeddingInvitationPage from "./pages/WeddingInvitationPage";
import WrinkleRemoverPage from "./pages/WrinkleRemoverPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import ThankYouPage from "./pages/ThankYouPage";
import AdminRoute from "./utils/AdminRoute";
import AdminHomePage from "./adminPages/HomePage";
import AdminApplicationsPage from "./adminPages/ApplicationsPage";
import AdminCategoriesPage from "./adminPages/CategoriesPage";
import AdminGraphicsPage from "./adminPages/GraphicPage";
import AdminTemplatesPage from "./adminPages/TemplatesPage";
import ShareImagePage from "./pages/ShareImage";
import MyPizap from "./pages/MyPizap";
import { ToastContainer, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";
import "./editor/main";
import "pixi.js";
import "pixi-picture";
import "./editor.css";

const App = () => {
  useEffect(() => {
    JSONUtils.readAssetData();
    if (!UserStore.getUser() && UserStore.getLegacyUser()) {
      UserActionCreators.legacyLoggedIn(UserStore.getLegacyUser());
    }
    const accessToken = UserStore.getAccessToken();
    if (accessToken) {
      UserActionCreators.updateUserData(accessToken);
    }
  }, []);

  return (
    <GoogleOAuthProvider clientId="369311205870.apps.googleusercontent.com">
      <Router>
        <Fragment>
          <Facebook />
          <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            transition={Flip}
          />
          <GoTop scrollStepInPx="100" delayInMs="10.50" />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/app/login" element={<LoginPage />} />
            <Route path="/app/signup" element={<SignupPage />} />
            <Route path="/photo_editor_app" element={<PhotoEditorPage />} />
            <Route path="/pizap" element={<PhotoEditorPage />} />
            <Route path="/editor" element={<PhotoEditorPage />} />
            <Route path="/photo_editor" element={<PhotoEditorPage />} />
            <Route path="/collage_maker_app" element={<CollageMakerPage />} />
            <Route path="/free_collage_maker" element={<CollageMakerPage />} />
            <Route path="/collage_maker" element={<CollageMakerPage />} />
            <Route
              path="/online_design_tool_app"
              element={<OnlineDesignPage />}
            />
            <Route path="/design_tool" element={<OnlineDesignPage />} />
            <Route path="/touch_up_tool_app" element={<TouchupToolPage />} />
            <Route path="/add_text_to_photos" element={<AddTextPhotoPage />} />
            <Route path="/custom_card_maker_app" element={<CustomCardPage />} />
            <Route
              path="/clip_art_pictures_stickers_app"
              element={<ClipArtStickerPage />}
            />
            <Route
              path="/photo_effects_filters_app"
              element={<PhotoEffectspage />}
            />
            <Route
              path="/1meme_maker_generator_app"
              element={<MemeMakerPage />}
            />
            <Route
              path="/photo_frames_borders_app"
              element={<FrameBordersPage />}
            />
            <Route
              path="/1facebook_cover_maker_app"
              element={<FacebookCovermakerPage />}
            />
            <Route
              path="/twitter_cover_maker_app"
              element={<TwitterCoverMakerPage />}
            />
            <Route path="/logo_maker_app" element={<LogoMakerPage />} />
            <Route
              path="/youtube_cover_maker_app"
              element={<YoutubeCovermakerPage />}
            />
            <Route
              path="/airbrush_makeup_app"
              element={<AirbrushMakeupPage />}
            />
            <Route
              path="/blemish_remover_tool_app"
              element={<BlemishRemoverToolPage />}
            />
            <Route
              path="/business_card_maker_app"
              element={<BusinessCardMakerPage />}
            />
            <Route
              path="/christmas_card_maker_app"
              element={<ChristmasCardMakerPage />}
            />
            <Route
              path="/cut_out_photo_tool_app"
              element={<CutoutToolPage />}
            />
            <Route path="/eye_color_changer_app" element={<EyeColorPage />} />
            <Route path="/face_editor_app" element={<FaceEditorPage />} />
            <Route path="/flyer_maker_app" element={<FlyerMakerPage />} />
            <Route path="/free_stock_images" element={<FreeStockImagePage />} />
            <Route
              path="/holiday_card_maker_app"
              element={<HolidayCardMakerPage />}
            />
            <Route
              path="/graphic_design_maker_app"
              element={<GraphicDesignMakerPage />}
            />
            <Route
              path="/lip_tint_changer_app"
              element={<LipTintChangePage />}
            />
            <Route
              path="/photo_backgrounds_textures_app"
              element={<PhotoBackgroundPage />}
            />
            <Route path="/photo_crop_tool_app" element={<PhotoCropPage />} />
            <Route path="/poster_maker_app" element={<PosterMakerPage />} />
            <Route
              path="/speech_bubbles_photo_app"
              element={<SpeechBubblesPage />}
            />
            <Route
              path="/teeth_whitening_photo_app"
              element={<TeethWhiteningPage />}
            />
            <Route
              path="/thank_you_card_maker_app"
              element={<ThankCardPage />}
            />
            <Route
              path="/wedding_invitation_maker_app"
              element={<WeddingInvitationPage />}
            />
            <Route
              path="/wrinkle_remover_app"
              element={<WrinkleRemoverPage />}
            />
            <Route path="/privacypolicy" element={<PrivacyPolicyPage />} />
            <Route path="/thankyou" element={<ThankYouPage />} />
            <Route path="/app/browser" element={<Browser />} />
            <Route path="/*" element={<PageNotFound />} />
            <Route path="/app/share" element={<ShareImagePage />} />
            <Route
              path="/app/"
              element={
                <ProtectedRoute>
                  <MainContent />
                </ProtectedRoute>
              }
            />
            <Route
              path="/app/start"
              element={
                <ProtectedRoute>
                  <Start />
                </ProtectedRoute>
              }
            />
            <Route
              path="/app/cutouts"
              element={
                <ProtectedRoute>
                  <Cutouts />
                </ProtectedRoute>
              }
            />
            <Route
              path="/app/projects"
              element={
                <ProtectedRoute>
                  <Projects />
                </ProtectedRoute>
              }
            />
            <Route
              path="/app/assets"
              element={
                <ProtectedRoute>
                  <Assets />
                </ProtectedRoute>
              }
            />
            <Route
              path="/app/exports"
              element={
                <ProtectedRoute>
                  <Exports />
                </ProtectedRoute>
              }
            />
            <Route
              path="/app/folders/:folder"
              element={
                <ProtectedRoute>
                  <Folders />
                </ProtectedRoute>
              }
            />
            <Route
              path="/app/templates/:tag"
              element={
                <ProtectedRoute>
                  <Templates />
                </ProtectedRoute>
              }
            />
            <Route
              path="/app/search/:keyword"
              element={
                <ProtectedRoute>
                  <SearchPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/app/templateSearch/:keyword"
              element={
                <ProtectedRoute>
                  <SearchPage2 />
                </ProtectedRoute>
              }
            />
            <Route
              path="/app/uploaded"
              element={
                <ProtectedRoute>
                  <Uploaded />
                </ProtectedRoute>
              }
            />
            <Route
              path="/app/applications/:app"
              element={
                <ProtectedRoute>
                  <Applications />
                </ProtectedRoute>
              }
            />
            <Route
              path="/gallery/mypizap"
              element={
                <ProtectedRoute>
                  <MyPizap />
                </ProtectedRoute>
              }
            />
            <Route
              path="/adminv3"
              element={
                <AdminRoute>
                  <AdminHomePage />
                </AdminRoute>
              }
            />
            <Route
              path="/adminv3/graphics"
              element={
                <AdminRoute>
                  <AdminGraphicsPage />
                </AdminRoute>
              }
            />
            <Route
              path="/adminv3/templates"
              element={
                <AdminRoute>
                  <AdminTemplatesPage />
                </AdminRoute>
              }
            />
            <Route
              path="/adminv3/categories"
              element={
                <AdminRoute>
                  <AdminCategoriesPage />
                </AdminRoute>
              }
            />
            <Route
              path="/adminv3/applications"
              element={
                <AdminRoute>
                  <AdminApplicationsPage />
                </AdminRoute>
              }
            />
          </Routes>
        </Fragment>
      </Router>
    </GoogleOAuthProvider>
  );
};

export default App;
