import React, { useEffect, useMemo, useState, useRef } from "react";
import Header from "../layouts/Header";
import UserLeftMenu from "../components/UserLeftMenu.react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import UserStore from "../stores/UserStore";
import { ReactComponent as IconPixabay } from "../images/editor/pixabay_logo.svg";
import { ReactComponent as IconUnsplash } from "../images/editor/unsplash_wordmark_logo.svg";
import { ReactComponent as IconClear } from "../images/editor/clear_icon.svg";
import { ReactComponent as IconSearch } from "../images/editor/search_icon.svg";
import AssetStore from "../stores/AssetStore";
import JSONUtils from "../utils/JSONUtils";
import * as AssetActionCreators from "../actions/AssetActionCreators";
import ImageGallery from "../components/ImageGallery";
import ProGet from "../components/ProGet.react";
import ProTeaser from "../components/ProTeaser.react";
import Autosuggest from "react-autosuggest";
import HelmetDetails from "../components/HelmetDetails";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import IconEmpty from "../images/editor/empty-folder.png";
import SkeletonLoading from "../components/SkeletonLoadingImage";

function getUnsplashFolderItem(searchItem, idx) {
  let item = {
    key: idx,
    src: searchItem.urls.regular,
    icon: searchItem.urls.thumb,
    originalItem: searchItem,
    isProOnly: false,
  };
  return <ImageGallery key={item.key} item={item} type="search" />;
}

const pizapFolderItem = (item, idx) => {
  let isProOnly = false;
  (item.tags || []).forEach((tag) => {
    if (typeof tag === "string" && tag.toLowerCase() === "pizappro") {
      isProOnly = true;
    }
  });
  let data = {
    key: idx,
    src: item.url,
    icon: item.thumbnail,
    isProOnly: isProOnly,
    originalItem: item,
  };
  return <ImageGallery key={data.key} item={data} type="search" />;
};

const templatesFolderItem = (item, idx) => {
  console.log("[templatesFolderItem] item at index", idx, ":", item);

  let isProOnly = false;
  if (Array.isArray(item.tags)) {
    item.tags.forEach((tag) => {
      if (typeof tag === "string" && tag.toLowerCase() === "pizappro") {
        isProOnly = true;
      }
    });
  } else {
    console.warn("[templatesFolderItem] item.tags is not an array:", item.tags);
  }

  let data = {
    key: idx,
    src: item.src,
    icon: item.thumbnail,
    isProOnly: isProOnly,
    originalItem: item,
  };

  console.log("[templatesFolderItem] data passed to ImageGallery:", data);

  return <ImageGallery key={data.key} item={data} type="templates" />;
};

const getStickerFolderItem = (item, idx) => {
  let data = {
    key: idx,
    src: item.src,
    icon: item.thumbnail,
    isProOnly: item.isProOnly,
    originalItem: item,
  };
  return <ImageGallery key={data.key} item={data} type="sticker_eraser" />;
};

function getSearchFolderItem(searchItem) {
  let item = {
    key: searchItem.id,
    src: `${
      process.env.REACT_APP_SERVER_API
    }/proxy/${searchItem?.fullHDURL?.replace("//", "")}`,
    icon: searchItem.previewURL,
    originalItem: searchItem,
    isProOnly: false,
  };
  return <ImageGallery key={item.key} item={item} type="search" />;
}

const getStatesFromStores = () => {
  return {
    featuredBackgrounds: AssetStore.getFeaturedBackgrounds(),
    startSearchResults: AssetStore.getStartSearchResults(),
    showPro: UserStore.showPro(),
    showProGet: UserStore.showProGet(),
  };
};

const SearchPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { keyword } = useParams();
  const [storesData, setStoresData] = useState(getStatesFromStores());
  const [searchValue, setSearchValue] = useState(keyword);
  const [isLoading, setIsLoading] = useState(false);
  const [suggestions, setSuggstions] = useState([]);
  const selectedSuggestion = useRef(null);

  const handleInputChange = (event, { newValue, method }) => {
    setSearchValue(newValue);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      if (selectedSuggestion.current === null) {
        handleSearchWvalue(e);
      }
    } else {
      selectedSuggestion.current = null;
      return;
    }
  };

  const inputProps = {
    placeholder: "Search...",
    value: searchValue,
    onChange: handleInputChange,
    onKeyDown: handleKeyDown,
  };

  const _onChange = () => {
    setStoresData(getStatesFromStores());
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      if (location.state === null || location.state.activeTab === "templates") {
        handleClickTemplatesTab();
      } else if (location.state.activeTab === "images_backgrounds") {
        if (location.state.subTab === "pizap") {
          handleClickPizapTab();
        } else if (location.state.subTab === "pixabay") {
          handleClickPixabayTab();
        } else if (location.state.subTab === "unsplash") {
          handleClickUnsplashTab();
        }
      } else if (location.state.activeTab === "stickers") {
        handleClickStickersTab();
      }
    }
    return () => {
      isMounted = false;
    };
  }, [location]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      UserStore.addChangeListener(_onChange);
      AssetStore.addChangeListener(_onChange);
    }
    return () => {
      isMounted = false;
      UserStore.removeChangeListener(_onChange);
      AssetStore.removeChangeListener(_onChange);
    };
  }, []);

  const getSuggestions = async (value) => {
    let items = [];
    await fetch(
      `${
        process.env.REACT_APP_SERVER_API
      }/pizapTemplate/searchTag/${encodeURIComponent(value)}`,
      {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + UserStore.getAccessToken(),
          "Content-Type": "application/json",
        }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((results) => {
        if (results.length && results.length > 0)
          items.push({ title: "Templates", items: results });
      });

    let bgs = [];
    const res = (storesData.featuredBackgrounds || [])
  .filter((item) => {
    // Also check if item.tag exists
    return item.tag && item.tag.toLowerCase().includes(value);
  });
    if (res.length > 0) {
      res.map((item) => bgs.push(item.tag));
      items.push({ title: "Images & Backgrounds", items: bgs });
    }
    await fetch(
      `${
        process.env.REACT_APP_SERVER_API
      }/pizapAsset/searchTag/${encodeURIComponent(value)}*`,
      {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + UserStore.getAccessToken(),
          "Content-Type": "application/json",
        }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((results) => {
        if (results.length && results.length > 0)
          items.push({ title: "Stickers", items: results });
      });
    return items;
  };

  const onSuggestionsFetchRequested = async ({ value }) => {
    const res = await getSuggestions(value);
    setSuggstions(res);
  };

  const onSuggestionsClearRequested = () => {
    setSuggstions([]);
  };

  const getSuggestionValue = (suggestion) => {
    setSearchValue(suggestion);
    return suggestion;
  };

  const renderSuggestion = (suggestion) => {
    return <span>{suggestion}</span>;
  };

  const renderSectionTitle = (section) => {
    return section.title;
  };

  const getSectionSuggestions = (section) => {
    return section.items;
  };

  const handleSearchWvalue = (e) => {
    // Suppose we only do the special redirect if the activeTab is "templates"
    if (storesData.startSearchResults.activeTab === "templates") {
      navigate(`/app/templateSearch/${encodeURIComponent(searchValue)}`);
    } else {
      navigate(`/app/search/${encodeURIComponent(searchValue)}`, {
        state: {
          activeTab: storesData.startSearchResults.activeTab,
          subTab: storesData.startSearchResults.subTab,
        },
      });
    }
  };

  const handleSearch = (e, suggestion) => {
    selectedSuggestion.current = true;
    let _activeTab = "templates";
    let _subTab = "";
  
    if (suggestions[suggestion.sectionIndex].title === "Templates") {
      _activeTab = "templates";
      _subTab = "";
  
      // Redirect to /app/templateSearch if you prefer
      navigate(`/app/templateSearch/${encodeURIComponent(suggestion.suggestion)}`);
      return;
    } else if (
      suggestions[suggestion.sectionIndex].title === "Images & Backgrounds"
    ) {
      _activeTab = "images_backgrounds";
      _subTab = "pizap";
    } else if (suggestions[suggestion.sectionIndex].title === "Stickers") {
      _activeTab = "stickers";
      _subTab = "";
    }
  
    // If it wasn't a template suggestion, do normal search
    navigate(`/app/search/${encodeURI(suggestion.suggestion)}`, {
      state: {
        activeTab: _activeTab,
        subTab: _subTab,
      },
    });
  };

  const dialog = useMemo(() => {
    if (storesData.showProGet) {
      return <ProGet />;
    }
    if (storesData.showPro) {
      return <ProTeaser />;
    }
  }, [storesData.showPro, storesData.showProGet]);

  const handleClickTemplatesTab = () => {
    navigate(`/app/templateSearch/${encodeURIComponent(keyword)}`);
  };

  // const handleClickTemplatesTab = () => {
  //   setIsLoading(true);
  
  //   fetch(`${process.env.REACT_APP_SERVER_API}/projectTag/tag/${keyword}`, {
  //     method: "get",
  //     headers: new Headers({
  //       Authorization: "Bearer " + UserStore.getAccessToken(),
  //       "Content-Type": "application/json",
  //     }),
  //   })
  //     .then((res) => {
  //       console.log("[Templates] Response status:", res.status);
  //       return res.json();
  //     })
  //     .then((data) => {
  //       console.log("[Templates] Raw data from server:", data);
  
  //       if (!Array.isArray(data)) {
  //         console.warn("[Templates] Expected an array, got:", data);
  //         setIsLoading(false);
  //         handleClickPizapTab();
  //         return;
  //       }
  
  //       let sequenceArray = [];
  //       let createdArray = [];
  
  //       // Log if any items are null
  //       const filteredData = data.filter((item, i) => {
  //         if (!item) {
  //           console.warn(`[Templates] null/undefined item at index ${i}`);
  //           return false;
  //         }
  //         return true;
  //       });
  
  //       filteredData.forEach((item, index) => {
  //         if (item.userName !== null) {
  //           if (item.sequence) {
  //             sequenceArray.push(item);
  //           } else {
  //             createdArray.push(item);
  //           }
  //         } else {
  //           console.log(
  //             `[Templates] item at index ${index} missing userName or is null?`,
  //             item
  //           );
  //         }
  //       });
  
  //       sequenceArray.sort((a, b) => a.sequence - b.sequence);
  //       createdArray.sort((a, b) => a.created - b.created);
  //       const images = [...sequenceArray, ...createdArray];
  
  //       console.log("[Templates] Final images array:", images);
  
  //       if (images.length > 0) {
  //         AssetActionCreators.setStartSearchResults({
  //           activeTab: "templates",
  //           subTab: "",
  //           results: images,
  //         });
  //       } else {
  //         console.log("[Templates] No valid templates, falling back to pizapTab");
  //         handleClickPizapTab();
  //       }
  //       setIsLoading(false);
  //     })
  //     .catch((err) => {
  //       console.error("[Templates] Fetch failed:", err);
  //       setIsLoading(false);
  //       handleClickPizapTab();
  //     });
  // };

  const handleClickStickersTab = () => {
    setIsLoading(true);
    fetch(
      `${
        process.env.REACT_APP_SERVER_API
      }/pizapAsset/search/${encodeURIComponent(keyword)}*`,
      {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + UserStore.getAccessToken(),
          "Content-Type": "application/json",
        }),
      }
    )
      .then(function (res) {
        return res.json();
      })
      .then(function (results) {
        if (results && results.length) {
          const items = results.map((item) => {
            let isPRO = false;
            if (item.tags) {
              item.tags.forEach((tag) => {
                if (tag === "pizapPRO") isPRO = true;
              });
            }
            return {
              src: item.url,
              thumbnail: item.thumbnail,
              isProOnly: isPRO,
            };
          });
          items.sort(function (a, b) {
            if (a.isProOnly && !b.isProOnly) return 1;
            if (b.isProOnly && !a.isProOnly) return -1;
            return 0;
          });
          setIsLoading(false);
          AssetActionCreators.setStartSearchResults({
            activeTab: "stickers",
            subTab: "",
            results: items,
          });
        } else {
          setIsLoading(false);
          AssetActionCreators.setStartSearchResults({
            activeTab: "stickers",
            subTab: "",
            results: [],
          });
        }
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  const handleClickPizapTab = async () => {
    setIsLoading(true);
    const results = await storesData.featuredBackgrounds
      ?.map((category) => {
        return category.pizapAssets.filter((item) => {
          if (
            item.tags &&
            item.tags.find(
              (ele) =>
                ele?.value?.toLowerCase().includes(keyword.toLowerCase()) ||
                (typeof ele === "string" &&
                  ele.toLowerCase().includes(keyword.toLowerCase()))
            )
          ) {
            return true;
          } else {
            return false;
          }
        });
      })
      .filter((item) => item.length > 0)
      .flat();
    if (results?.length > 0) {
      AssetActionCreators.setStartSearchResults({
        activeTab: "images_backgrounds",
        subTab: "pizap",
        results: results,
      });
    } else {
      handleClickPixabayTab();
    }
    setIsLoading(false);
  };

  const handleClickPixabayTab = () => {
    setIsLoading(true);
    JSONUtils.getJSON(
      "https://www.pizap.com/pixabay/search?q=" + encodeURI(keyword),
      "GET",
      null,
      function (data) {
        if (data.hits) {
          AssetActionCreators.setStartSearchResults({
            activeTab: "images_backgrounds",
            subTab: "pixabay",
            results: data.hits,
          });
        }
        setIsLoading(false);
      }
    );
  };

  const handleClickUnsplashTab = () => {
    setIsLoading(true);
    JSONUtils.getJSON(
      `${process.env.REACT_APP_SERVER_API}/unsplash?query=${encodeURI(
        keyword
      )}`,
      "GET",
      null,
      function (data) {
        if (data.total) {
          AssetActionCreators.setStartSearchResults({
            activeTab: "images_backgrounds",
            subTab: "unsplash",
            results: data.results,
          });
        }
        setIsLoading(false);
      }
    );
  };

  const contents = useMemo(() => {
    if (storesData.startSearchResults.activeTab === "templates") {
      const resultArray = storesData.startSearchResults?.results || [];
      console.log("[SearchPage] about to map over template results:", resultArray);
  
      return resultArray.length > 0 ? (
        <ResponsiveMasonry
          className="search-masonry"
          columnsCountBreakPoints={{
            350: 2,
            750: 3,
            900: 4,
            1024: 5,
            1200: 7,
          }}
        >
          <Masonry gutter="16px">
            {resultArray.map(templatesFolderItem)}
          </Masonry>
        </ResponsiveMasonry>
      ) : (
        <img className="empty-folder" src={IconEmpty} />
      );
    } else if (storesData.startSearchResults.activeTab === "images_backgrounds") {
      if (storesData.startSearchResults.subTab === "pizap") {
        return storesData.startSearchResults?.results &&
          storesData.startSearchResults.results?.length > 0 ? (
          <ResponsiveMasonry
            className="search-masonry"
            columnsCountBreakPoints={{
              350: 2,
              750: 3,
              900: 4,
              1024: 5,
              1200: 7,
            }}
          >
            <Masonry gutter="16px">
              {storesData.startSearchResults.results.map(pizapFolderItem)}
            </Masonry>
          </ResponsiveMasonry>
        ) : (
          <img className="empty-folder" src={IconEmpty} />
        );
      } else if (storesData.startSearchResults.subTab === "pixabay") {
        return storesData.startSearchResults?.results &&
          storesData.startSearchResults.results?.length > 0 ? (
          <ResponsiveMasonry
            className="search-masonry"
            columnsCountBreakPoints={{
              350: 2,
              750: 3,
              900: 4,
              1024: 5,
              1200: 7,
            }}
          >
            <Masonry gutter="16px">
              {storesData.startSearchResults.results.map(getSearchFolderItem)}
            </Masonry>
          </ResponsiveMasonry>
        ) : (
          <img className="empty-folder" src={IconEmpty} />
        );
      } else if (storesData.startSearchResults.subTab === "unsplash") {
        return storesData.startSearchResults?.results &&
          storesData.startSearchResults.results?.length > 0 ? (
          <ResponsiveMasonry
            className="search-masonry"
            columnsCountBreakPoints={{
              350: 2,
              750: 3,
              900: 4,
              1024: 5,
              1200: 7,
            }}
          >
            <Masonry gutter="16px">
              {storesData.startSearchResults.results.map(getUnsplashFolderItem)}
            </Masonry>
          </ResponsiveMasonry>
        ) : (
          <img className="empty-folder" src={IconEmpty} />
        );
      }
    } else if (storesData.startSearchResults.activeTab === "stickers") {
      return storesData.startSearchResults.results &&
        storesData.startSearchResults.results?.length > 0 ? (
        <ResponsiveMasonry
          className="search-masonry"
          columnsCountBreakPoints={{
            350: 2,
            750: 3,
            900: 4,
            1024: 5,
            1200: 7,
          }}
        >
          <Masonry gutter="16px">
            {storesData.startSearchResults.results.map(getStickerFolderItem)}
          </Masonry>
        </ResponsiveMasonry>
      ) : (
        <img className="empty-folder" src={IconEmpty} />
      );
    }
  }, [storesData.startSearchResults, keyword]);

  return (
    <div className="startPage">
      <HelmetDetails
        keywords=""
        title="piZap - free online photo editor - fun photo effects editor"
        description="piZap Photo Editor is fun and easy to learn online photo editor & collage maker. Tons of effects, fonts, stickers, collage layouts, borders, frames, and editing tools."
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl={`https://www.pizap.com/app/search/${keyword}`}
      />
      <Header />
      <div className="container" id="container">
        <div className="quickControls">
          <div className="input-group">
            <Autosuggest
              multiSection={true}
              suggestions={suggestions}
              onSuggestionsFetchRequested={onSuggestionsFetchRequested}
              onSuggestionsClearRequested={onSuggestionsClearRequested}
              onSuggestionSelected={handleSearch}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              renderSectionTitle={renderSectionTitle}
              getSectionSuggestions={getSectionSuggestions}
              inputProps={inputProps}
            />
            <span className="start__search__icon">
              <IconSearch />
            </span>
            <span
              className="start__clear__icon"
              onClick={(e) => navigate("/app/start")}
            >
              <IconClear />
            </span>
          </div>
          <div className="image-src-tabs">
            <div
              className={`tab-item ${
                storesData.startSearchResults.activeTab === "templates"
                  ? `selected`
                  : ``
              }`}
              onClick={(e) => handleClickTemplatesTab()}
            >
              <span>Templates</span>
            </div>
            <div
              className={`tab-item ${
                storesData.startSearchResults.activeTab === "images_backgrounds"
                  ? `selected`
                  : ``
              }`}
              onClick={(e) => handleClickPizapTab()}
            >
              <span>Images & Backgrounds</span>
            </div>
            <div
              className={`tab-item ${
                storesData.startSearchResults.activeTab === "stickers"
                  ? `selected`
                  : ``
              }`}
              onClick={(e) => handleClickStickersTab()}
            >
              <span>Stickers</span>
            </div>
          </div>
        </div>
        {storesData.startSearchResults.activeTab === "images_backgrounds" && (
          <div className="image-src-tabs">
            <div
              className={`tab-item ${
                storesData.startSearchResults.subTab === "pizap"
                  ? `selected`
                  : ``
              }`}
              onClick={(e) => handleClickPizapTab(e)}
            >
              <img
                src="https://static.pizap.com/html5/resources/editor/pizap_logo.svg"
                height={16}
              />
            </div>
            <div
              className={`tab-item ${
                storesData.startSearchResults.subTab === "pixabay"
                  ? `selected`
                  : ``
              }`}
              onClick={(e) => handleClickPixabayTab(e)}
            >
              <IconPixabay height={16} />
            </div>
            <div
              className={`tab-item ${
                storesData.startSearchResults.subTab === "unsplash"
                  ? `selected`
                  : ``
              }`}
              onClick={(e) => handleClickUnsplashTab(e)}
            >
              <IconUnsplash height={16} />
            </div>
          </div>
        )}

        {isLoading ? (
          <ResponsiveMasonry
            className="search-masonry"
            columnsCountBreakPoints={{
              350: 2,
              750: 3,
              900: 4,
              1024: 5,
              1200: 7,
            }}
          >
            <Masonry gutter="16px">
              <SkeletonLoading width="100%" height="160px" />
              <SkeletonLoading width="100%" height="160px" />
              <SkeletonLoading width="100%" height="160px" />
              <SkeletonLoading width="100%" height="160px" />
              <SkeletonLoading width="100%" height="160px" />
              <SkeletonLoading width="100%" height="160px" />
              <SkeletonLoading width="100%" height="160px" />
            </Masonry>
          </ResponsiveMasonry>
        ) : (
          contents
        )}
      </div>
      <UserLeftMenu />
      {dialog}
    </div>
  );
};

export default SearchPage;
