import EditorDispatcher from "../dispatcher/EditorDispatcher";
import { EventEmitter } from "events";
import assign from "object-assign";
import UserStore from "./UserStore";
import ActionTypes from "../constants/EditorConstants";
import LeftMenuStore from "./LeftMenuStore";

EventEmitter.defaultMaxListeners = 15;
var CHANGE_EVENT = "change";
var _assets;
var _selectedItem = null;
let _rememberPhotos = false;
let _cachedPhotos = {
  title: null,
  activeTab: "piZap",
  items: [],
};
let _rememberStickers = false;
let _cachedStickers = {
  title: null,
  activeTab: "piZap",
  items: [],
  isPro: false,
};
let _featuredGraphics = null;
let _featuredGraphicsEmoji = null;
let _featuredGraphicsMeme = null;
let _stickerBingResults = [];
let _graphics = null;
let _templates = null;
let _categories = null;
let _applications = null;
let _applicationsObj = null;
let _aiStyles = null;
let _aiStickerStyles = null;
let _textTemplates = null;
let _aiSuggestions = null;
var _featuredTemplates = null;
var _featuredBackgrounds = null;
var _featuredBackgroundsMeme = null;
var _featuredBackgroundsEmoji = null;
var _printProducts = null;
let _isMenuDataLoading = false;
var _graphicScrollReset = false;
var _resetGraphics = false;
let _pickerSearchResults = {
  activeTab: "main",
  catname: "",
  results: [],
};
let _startSearchResults = {
  activeTab: "templates",
  subTab: "pizap",
  results: [],
};
function zeroPad(num, places) {
  var zero = places - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join("0") + num;
}

function addStickers(item, path, folders, page) {
  var basePath = path + item.Path;
  var thumbnailExt = "png";
  var thumbnailPrefix = "thumb";
  var thumbnailDigits = 1;
  var pathPrefix = "img";
  var ProPathPrefix = null;
  var pathExt = "png";
  var pathDigits = 1;
  var PROAfter = 10000;

  if (item.ThumbnailExt) thumbnailExt = item.ThumbnailExt;
  if (item.ThumbnailPrefix) thumbnailPrefix = item.ThumbnailPrefix;
  if (item.ThumbnailDigits) thumbnailDigits = item.ThumbnailDigits;
  if (item.PROAfter) PROAfter = item.PROAfter;
  if (item.PathExt) pathExt = item.PathExt;
  if (item.PathPrefix) pathPrefix = item.PathPrefix;
  if (item.PROPathPrefix) ProPathPrefix = item.PROPathPrefix;
  if (item.PathDigits) pathDigits = item.PathDigits;
  var p, j, thumb;
  if (item.ItemCount) {
    for (j = 1; j <= item.ItemCount; j++) {
      thumb =
        basePath +
        thumbnailPrefix +
        zeroPad(j, thumbnailDigits) +
        "." +
        thumbnailExt;
      var source =
        basePath + pathPrefix + zeroPad(j, pathDigits) + "." + pathExt;
      var ProSource = null;
      if (ProPathPrefix) {
        ProSource =
          basePath + ProPathPrefix + zeroPad(j, pathDigits) + "." + pathExt;
      }
      p = {
        key: _selectedItem.name + j,
        src: source,
        icon: thumb,
        proSource: ProSource,
      };
      if (page >= 0) {
        p = {
          key: _selectedItem.name + page + "_" + j,
          src: source,
          icon: thumb,
          proSource: ProSource,
        };
      }
      if (j > PROAfter) {
        p.isProOnly = true;
      } else {
        p.isProOnly = false;
      }
      if (
        item.nonRectangle &&
        item.nonRectangle.includes &&
        item.nonRectangle.includes(j)
      ) {
        p.isRectangle = false;
      } else {
        if (item.isRectangle) p.isRectangle = item.isRectangle;
      }
      if (item.HasBackground) p.HasBackground = item.HasBackground;
      if (item.exclude && item.exclude.includes && item.exclude.includes(j)) {
      } else {
        folders.push(p);
      }
    }
  } else {
    basePath = path;
    for (j = 0; j < item.items.length; j++) {
      thumb = basePath + item.items[j].Thumb;
      var i = item.items[j];
      i.basePath = basePath;
      p = {
        key: item.items[j].Name,
        name: item.items[j].Name,
        icon: thumb,
        item: i,
      };

      if (j > PROAfter) {
        p.isProOnly = true;
      } else {
        p.isProOnly = false;
      }
      folders.push(p);
    }
  }
  return folders;
}
function addStickersSVG(item, path, folders, page) {
  var basePath = path + item.Path;
  var thumbnailExt = "png";
  var thumbnailPrefix = "thumb";
  var thumbnailDigits = 1;
  var pathPrefix = "img";
  var pathExt = "png";
  var pathDigits = 1;
  var PROAfter = 10000;

  if (item.ThumbnailExt) thumbnailExt = item.ThumbnailExt;
  if (item.ThumbnailPrefix) thumbnailPrefix = item.ThumbnailPrefix;
  if (item.ThumbnailDigits) thumbnailDigits = item.ThumbnailDigits;
  if (item.PROAfter) PROAfter = item.PROAfter;
  if (item.PathExt) pathExt = item.PathExt;
  if (item.PathPrefix) pathPrefix = item.PathPrefix;
  if (item.PathDigits) pathDigits = item.PathDigits;

  for (var j = 1; j <= item.ItemCount; j++) {
    var thumb =
      basePath +
      thumbnailPrefix +
      zeroPad(j, thumbnailDigits) +
      "." +
      thumbnailExt;
    var source = basePath + pathPrefix + zeroPad(j, pathDigits) + "." + pathExt;
    var p = { key: item.Name + j, src: source, icon: thumb };
    if (page >= 0) {
      p = { key: item.Name + page + "_" + j, src: source, icon: thumb };
    }
    if (j > PROAfter) {
      p.isProOnly = true;
    } else {
      p.isProOnly = false;
    }
    folders.push(p);
  }
  return folders;
}
var AssetStore = assign({}, EventEmitter.prototype, {
  init: function (data) {
    _assets = data;
  },

  emitChange: function () {
    this.emit(CHANGE_EVENT);
  },

  /**
   * @param {function} callback
   */
  addChangeListener: function (callback) {
    this.on(CHANGE_EVENT, callback);
  },

  /**
   * @param {function} callback
   */
  removeChangeListener: function (callback) {
    this.removeListener(CHANGE_EVENT, callback);
  },

  /**
   * @param {string} id
   */
  get: function () {
    return _assets;
  },
  getGraphicScrollReset: function () {
    return _graphicScrollReset;
  },
  setGraphicScrollReset: function (val) {
    _graphicScrollReset = val;
  },
  getResetGraphics: function () {
    return _resetGraphics;
  },
  setResetGraphics: function (val) {
    _resetGraphics = val;
    AssetStore.emitChange();
  },
  getCachedStickers: function () {
    return _cachedStickers;
  },
  setCachedStickers: function (item) {
    _cachedStickers = item;
    AssetStore.emitChange();
  },
  getRememberStickers: function () {
    return _rememberStickers;
  },
  setRememberStickers: function (bValue) {
    _rememberStickers = bValue;
    AssetStore.emitChange();
  },
  getCachedPhotos: function () {
    return _cachedPhotos;
  },
  setCachedPhotos: function (item) {
    _cachedPhotos = item;
    AssetStore.emitChange();
  },
  getRememberPhotos: function () {
    return _rememberPhotos;
  },
  setRememberPhotos: function (bVale) {
    _rememberPhotos = bVale;
  },
  getSelectedItem: function () {
    return _selectedItem;
  },
  setSelectedItem: function (item) {
    _selectedItem = item;
    _graphicScrollReset = true;
  },
  getFeaturedGraphics: function () {
    return _featuredGraphics;
  },
  getFeaturedGraphicsEmoji: function () {
    return _featuredGraphicsEmoji;
  },
  getFeaturedGraphicsMeme: function () {
    return _featuredGraphicsMeme;
  },
  getStickerBingResults: function () {
    return _stickerBingResults;
  },
  getPickerSearchResults: function () {
    return _pickerSearchResults;
  },
  setPickerSearchResults: function (data) {
    _pickerSearchResults = {
      activeTab: data.activeTab,
      catname: data.catname,
      results: data.results,
    };
  },
  setStartSearchResults: function (data) {
    _startSearchResults = {
      activeTab: data.activeTab,
      subTab: data.subTab,
      results: data.results,
    };
  },
  getGraphics: function () {
    return _graphics;
  },
  setGraphics: function (data) {
    _graphics = data;
  },
  getTemplates: function () {
    return _templates;
  },
  setTemplates: function (data) {
    _templates = data;
  },
  getCategories: function () {
    return _categories;
  },
  setCategories: function (data) {
    _categories = data;
  },
  getApplications: function () {
    return _applications;
  },
  setApplications: function (data) {
    _applications = data;
  },
  getApplicationsObj: function () {
    return _applicationsObj;
  },
  getAISuggestions: function () {
    return _aiSuggestions;
  },
  getAIStyles: function () {
    return _aiStyles;
  },
  getAIStickerStyles: function () {
    return _aiStickerStyles;
  },
  getTextTemplates: function () {
    return _textTemplates;
  },
  getFeaturedTemplates: function () {
    return _featuredTemplates;
  },
  getFeaturedBackgrounds: function () {
    return _featuredBackgrounds;
  },
  getFeaturedBackgroundsMeme: function () {
    return _featuredBackgroundsMeme;
  },
  getFeaturedBackgroundsEmoji: function () {
    return _featuredBackgroundsEmoji;
  },
  getStartSearchResults: function () {
    return _startSearchResults;
  },
  getPrintProducts: function () {
    return _printProducts;
  },
  getMenuDataLoading: function () {
    return _isMenuDataLoading;
  },
  getAllStickerFolders: function () {
    var folders = [];
    if (!_assets) return folders;
    var basePath = _assets.CDNPath + _assets.Stickers.Path;
    var last = "";

    for (var i = 0; i < _assets.Stickers.items.length; i++) {
      if (last !== _assets.Stickers.items[i].Name) {
        folders.push({
          key: _assets.Stickers.items[i].Name,
          name: _assets.Stickers.items[i].Name,
          icon: basePath + _assets.Stickers.items[i].Thumb,
        });
      }
      last = _assets.Stickers.items[i].Name;
    }
    return folders;
  },
  getAllFontFolders: function () {
    var folders = [];
    if (!_assets) return folders;
    var basePath = _assets.CDNPath + _assets.FontsCat.Path;
    var last = "";
    var catFunc = function (item) {
      counter++;
      item.Icon = basePath + item.Thumb;
      if (counter > PROAfter) {
        item.isProOnly = true;
      } else {
        item.isProOnly = false;
      }
      list.push(item);
      return null;
    };
    for (var i = 0; i < _assets.FontsCat.Categories.length; i++) {
      if (last !== _assets.FontsCat.Categories[i].Name) {
        var list = [];
        var PROAfter = 10000;
        if (_assets.FontsCat.Categories[i].PROAfter) {
          PROAfter = _assets.FontsCat.Categories[i].PROAfter;
        }
        var counter = 0;
        _assets.FontsCat.Categories[i].items.map(catFunc);
        folders.push({
          key: _assets.FontsCat.Categories[i].Name,
          name: _assets.FontsCat.Categories[i].Name,
          icon: basePath + _assets.FontsCat.Categories[i].Thumb,
          items: list,
        });
      }
      last = _assets.FontsCat.Categories[i].Name;
    }
    return folders;
  },
  getAllStickerSVGFolders: function () {
    var folders = [];
    if (!_assets) return folders;
    var basePath = _assets.CDNPath + _assets.StickersSVG.Path;

    for (var i = 0; i < _assets.StickersSVG.Categories.length; i++) {
      var subCats = [];
      for (
        var j = 0;
        j < _assets.StickersSVG.Categories[i].Categories.length;
        j++
      ) {
        var subCatItems = [];
        addStickersSVG(
          _assets.StickersSVG.Categories[i].Categories[j],
          basePath,
          subCatItems
        );
        subCats.push({
          Name: _assets.StickersSVG.Categories[i].Categories[j].Name,
          Thumb:
            basePath + _assets.StickersSVG.Categories[i].Categories[j].Thumb,
          PROAfter: _assets.StickersSVG.Categories[i].Categories[j].PROAfter,
          items: subCatItems,
        });
      }
      folders.push({
        Name: _assets.StickersSVG.Categories[i].Name,
        PROOnly: _assets.StickersSVG.Categories[i].PROOnly,
        Thumb: basePath + _assets.StickersSVG.Categories[i].Thumb,
        Categories: subCats,
      });
    }
    var shapeItems = [];

    addStickersSVG(
      _assets.StickerShapes.items[0],
      _assets.CDNPath + _assets.StickerShapes.Path,
      shapeItems
    );
    folders.unshift({
      Name: "Basic Shapes",
      PROOnly: false,
      Thumb:
        _assets.CDNPath + "html5/resources/clipart/shapes/cutout+shapes_1.svg",
      Categories: [
        {
          Name: "Basic Shapes",
          Thumb:
            _assets.CDNPath +
            "html5/resources/clipart/shapes/cutout+shapes_1.svg",
          PROAfter: 65,
          items: shapeItems,
        },
      ],
    });
    return folders;
  },
  getStickerFolderDetails: function () {
    if (
      _selectedItem &&
      _selectedItem.items &&
      _selectedItem.items.length > 0
    ) {
      return _selectedItem.items;
    }
    var folders = [];
    if (!_assets) return folders;
    var basePath = _assets.CDNPath + _assets.Stickers.Path;
    if (!_selectedItem) return folders;
    for (var i = 0; i < _assets.Stickers.items.length; i++) {
      if (_selectedItem.name === _assets.Stickers.items[i].Name) {
        var item = _assets.Stickers.items[i];
        addStickers(item, basePath, folders);
        if (item.Pages) {
          for (var j = 0; j < item.Pages.length; j++) {
            addStickers(item.Pages[j], basePath, folders, j);
          }
        }
      }
    }
    return folders;
  },
  getBorderFolders: function () {
    var folders = [];
    if (!_assets) return folders;
    var basePath = _assets.CDNPath + _assets.Borders.Path;
    var last = "";

    for (var i = 0; i < _assets.Borders.items.length; i++) {
      if (last !== _assets.Borders.items[i].Name) {
        folders.push({
          Name: _assets.Borders.items[i].Name,
          PROOnly: _assets.Borders.items[i].PROOnly,
          Thumb: basePath + _assets.Borders.items[i].Thumb,
          Categories: [],
        });
      }
      last = _assets.Borders.items[i].Name;
    }
    return folders;
  },
  getBorderFolderDetails: function () {
    var folders = [];
    if (!_assets) return folders;
    var basePath = _assets.CDNPath + _assets.Borders.Path;
    if (!_selectedItem) return folders;
    for (var i = 0; i < _assets.Borders.items.length; i++) {
      if (_selectedItem.Name === _assets.Borders.items[i].Name) {
        var item = _assets.Borders.items[i];
        addStickersSVG(item, basePath, folders);
        if (item.Pages) {
          for (var j = 0; j < item.Pages.length; j++) {
            addStickersSVG(item.Pages[j], basePath, folders, j);
          }
        }
      }
    }
    return folders;
  },
  getBackgroundFolders: function () {
    var folders = [];
    if (!_assets) return folders;
    var basePath = _assets.CDNPath + _assets.Backgrounds.Path;
    var last = "";

    for (var i = 0; i < _assets.Backgrounds.items.length; i++) {
      if (last !== _assets.Backgrounds.items[i].Name) {
        folders.push({
          key: _assets.Backgrounds.items[i].Name,
          name: _assets.Backgrounds.items[i].Name,
          icon: basePath + _assets.Backgrounds.items[i].Thumb,
        });
      }
      last = _assets.Backgrounds.items[i].Name;
    }
    return folders;
  },
  getShapesDetails: function () {
    var folders = [];
    if (!_assets) return folders;
    var basePath = _assets.CDNPath + _assets.Shapes.Path;
    for (var i = 0; i < _assets.Shapes.items.length; i++) {
      var item = _assets.Shapes.items[i];
      _selectedItem = { name: "shapes" };
      addStickers(item, basePath, folders);
      if (item.Pages) {
        for (var j = 0; j < item.Pages.length; j++) {
          addStickers(item.Pages[j], basePath, folders, j);
        }
      }
    }
    return folders;
  },
  getFacebookFolders: function () {
    var folders = [];

    return folders;
  },
  getBackgroundFolderDetails: function () {
    var folders = [];
    if (!_selectedItem) return folders;
    return _selectedItem.pizapAssets;
  },
  getTouchupFolders: function () {
    var folders = [];
    if (!_assets) return folders;
    var basePath = _assets.CDNPath + _assets.Touchup.Path;

    for (var i = 0; i < _assets.Touchup.items.length; i++) {
      folders.push({
        name: _assets.Touchup.items[i].Name,
        isProOnly: _assets.Touchup.items[i].isPRO,
        icon: basePath + _assets.Touchup.items[i].Thumb,
        colors: _assets.Touchup.items[i].colors,
        item: _assets.Touchup.items[i],
      });
    }
    return folders;
  },
  getEffectFolders: function () {
    var folders = [];
    if (!_assets) return folders;
    var basePath = _assets.CDNPath + _assets.Effects.Path;
    var last = "";

    for (var i = 0; i < _assets.Effects.items.length; i++) {
      if (last !== _assets.Effects.items[i].Name) {
        folders.push({
          Name: _assets.Effects.items[i].Name,
          PROOnly: _assets.Effects.items[i].PROOnly,
          Thumb: basePath + _assets.Effects.items[i].Thumb,
          PROAfter: _assets.Effects.items[i].PROAfter,
          Categories: [],
        });
      }
      last = _assets.Effects.items[i].Name;
    }
    return folders;
  },
  getEffectFolderDetails: function () {
    var folders = [];
    if (!_assets) return folders;
    var basePath = _assets.CDNPath + _assets.Effects.Path;
    if (LeftMenuStore.get() === "effect") {
      if (!_selectedItem) return folders;
    } else {
      if (!_selectedItem?.items || _selectedItem?.Name !== "Classic") {
        _selectedItem = _assets.Effects.items[1];
      }
    }
    if (!_selectedItem) return folders;
    for (var i = 0; i < _assets.Effects.items.length; i++) {
      if (_selectedItem.Name === _assets.Effects.items[i].Name) {
        var item = _assets.Effects.items[i];
        addStickers(item, basePath, folders);
        if (item.Pages) {
          for (var j = 0; j < item.Pages.length; j++) {
            addStickers(item.Pages[j], basePath, folders, j);
          }
        }
      }
    }
    return folders;
  },
  getDesignFolders: function () {
    var folders = [];
    if (!_assets) return folders;
    var last = "";

    for (var i = 0; i < _assets.DesignTemplates.items.length; i++) {
      if (last !== _assets.DesignTemplates.items[i].Name) {
        folders.push({
          Name: _assets.DesignTemplates.items[i].Name,
          PROOnly: _assets.DesignTemplates.items[i].PROOnly,
          Categories: [],
        });
      }
      last = _assets.DesignTemplates.items[i].Name;
    }
    return folders;
  },
  getCollageFolders: function () {
    var folders = [];
    if (!_assets) return folders;
    var basePath = _assets.CDNPath + _assets.Collages.Path;
    var last = "";

    for (var i = 0; i < _assets.Collages.items.length; i++) {
      if (last !== _assets.Collages.items[i].Name) {
        folders.push({
          Name: _assets.Collages.items[i].Name,
          PROOnly: _assets.Collages.items[i].PROOnly,
          icon: basePath + _assets.Collages.items[i].Thumb,
          Categories: [],
          isRectangle: _assets.Collages.items[i].isRectangle,
          nonRectangle: _assets.Collages.items[i].nonRectangle || [],
          exclude: _assets.Collages.items[i].exclude || [],
          HasBackground: _assets.Collages.items[i].HasBackground,
        });
      }
      last = _assets.Collages.items[i].Name;
    }
    return folders;
  },
  getCollageFolderDetails: function () {
    var folders = [];
    if (!_assets) return folders;
    var basePath = _assets.CDNPath + _assets.Collages.Path;
    if (!_selectedItem?.name) {
      _selectedItem = {
        name: _assets.Collages.items[0].Name,
        key: _assets.Collages.items[0].Name,
        isProOnly: _assets.Collages.items[0].PROOnly,
        icon: basePath + _assets.Collages.items[0].Thumb,
        isRectangle: _assets.Collages.items[0].isRectangle,
        nonRectangle: _assets.Collages.items[0].nonRectangle || [],
        HasBackground: _assets.Collages.items[0].HasBackground,
      };
    }
    for (var i = 0; i < _assets.Collages.items.length; i++) {
      if (_selectedItem.name === _assets.Collages.items[i].Name) {
        var item = _assets.Collages.items[i];
        item.isRectangle = _selectedItem.isRectangle;
        item.nonRectangle = _selectedItem.nonRectangle;
        item.HasBackground = _selectedItem.HasBackground;
        addStickers(item, basePath, folders);
      }
    }
    return folders;
  },
  getDesignFolderDetails: function () {
    var folders = [];
    if (!_assets) return folders;
    var basePath = _assets.CDNPath + _assets.DesignTemplates.Path;
    _selectedItem = _assets.DesignTemplates.items[0];
    _selectedItem.name = _assets.DesignTemplates.items[0].Name;
    for (var i = 0; i < _assets.DesignTemplates.items.length; i++) {
      if (_selectedItem.name === _assets.DesignTemplates.items[i].Name) {
        var item = _assets.DesignTemplates.items[i];
        addStickers(item, basePath, folders);
      }
    }
    return folders;
  },
  getFonts: function () {
    var folders = [];
    if (!_assets) return folders;
    var basePath = _assets.CDNPath + _assets.Fonts.Path;

    for (var i = 0; i < _assets.Fonts.items.length; i++) {
      folders.push({
        key: _assets.Fonts.items[i].Name,
        name: _assets.Fonts.items[i].Name,
        icon: basePath + _assets.Fonts.items[i].Thumb,
        src: basePath + _assets.Fonts.items[i].Path,
      });
    }
    return folders;
  },
});

AssetStore.dispatchToken = EditorDispatcher.register(function (action) {
  switch (action.type) {
    case ActionTypes.ASSETS_RECEIVED:
      _graphicScrollReset = true;
      AssetStore.init(action.data);
      AssetStore.emitChange();
      try {
        if (_featuredGraphics === null) {
          fetch(`${process.env.REACT_APP_SERVER_API}/pizapAsset/featured`, {
            method: "get",
            headers: new Headers({
              Authorization: "Bearer " + UserStore.getAccessToken(),
              "Content-Type": "application/json",
            }),
          })
            .then(function (res) {
              return res.json();
            })
            .then(function (featured) {
              featured.forEach((category) => {
                if (category.pizapAssets) {
                  category.pizapAssets.sort(function (a, b) {
                    let isPROa = false;
                    if (a.tags) {
                      a.tags.forEach((tag) => {
                        if (tag === "pizapPRO") isPROa = true;
                      });
                    }
                    let isPROb = false;
                    if (b.tags) {
                      b.tags.forEach((tag) => {
                        if (tag === "pizapPRO") isPROb = true;
                      });
                    }
                    if (isPROa && !isPROb) return 1;
                    if (isPROb && !isPROa) return -1;
                    return 0;
                  });
                }
              });
              _featuredGraphics = featured;
              AssetStore.emitChange();
            })
            .catch((e) => {
              console.log(e);
            });
        }
        if (_featuredGraphicsEmoji === null) {
          fetch(
            `${process.env.REACT_APP_SERVER_API}/pizapAsset/featured?app=emoji`,
            {
              method: "get",
              headers: new Headers({
                Authorization: "Bearer " + UserStore.getAccessToken(),
                "Content-Type": "application/json",
              }),
            }
          )
            .then(function (res) {
              return res.json();
            })
            .then(function (featured) {
              featured.forEach((category) => {
                if (category.pizapAssets) {
                  category.pizapAssets.sort(function (a, b) {
                    let isPROa = false;
                    if (a.tags) {
                      a.tags.forEach((tag) => {
                        if (tag === "pizapPRO") isPROa = true;
                      });
                    }
                    let isPROb = false;
                    if (b.tags) {
                      b.tags.forEach((tag) => {
                        if (tag === "pizapPRO") isPROb = true;
                      });
                    }
                    if (isPROa && !isPROb) return 1;
                    if (isPROb && !isPROa) return -1;
                    return 0;
                  });
                }
              });
              _featuredGraphicsEmoji = featured;
              AssetStore.emitChange();
            })
            .catch((e) => {
              console.log(e);
            });
        }
        if (_featuredGraphicsMeme === null) {
          fetch(
            `${process.env.REACT_APP_SERVER_API}/pizapAsset/featured?app=meme`,
            {
              method: "get",
              headers: new Headers({
                Authorization: "Bearer " + UserStore.getAccessToken(),
                "Content-Type": "application/json",
              }),
            }
          )
            .then(function (res) {
              return res.json();
            })
            .then(function (featured) {
              featured.forEach((category) => {
                if (category.pizapAssets) {
                  category.pizapAssets.sort(function (a, b) {
                    let isPROa = false;
                    if (a.tags) {
                      a.tags.forEach((tag) => {
                        if (tag === "pizapPRO") isPROa = true;
                      });
                    }
                    let isPROb = false;
                    if (b.tags) {
                      b.tags.forEach((tag) => {
                        if (tag === "pizapPRO") isPROb = true;
                      });
                    }
                    if (isPROa && !isPROb) return 1;
                    if (isPROb && !isPROa) return -1;
                    return 0;
                  });
                }
              });
              _featuredGraphicsMeme = featured;
              AssetStore.emitChange();
            })
            .catch((e) => console.log(e));
        }
        if (_featuredTemplates === null) {
          fetch(`${process.env.REACT_APP_SERVER_API}/pizapTemplate/featured`, {
            method: "get",
            headers: new Headers({
              Authorization: "Bearer " + UserStore.getAccessToken(),
              "Content-Type": "application/json",
            }),
          })
            .then(function (res) {
              return res.json();
            })
            .then(function (featured) {
              _graphicScrollReset = true;

              featured.forEach((category) => {
                if (category.photoResponses) {
                  category.photoResponses.sort(function (a, b) {
                    var isPROa = false;
                    if (a.tags) {
                      a.tags.forEach((tag) => {
                        if (tag === "pizapPRO") isPROa = true;
                      });
                    }

                    var isPROb = false;
                    if (b.tags) {
                      b.tags.forEach((tag) => {
                        if (tag === "pizapPRO") isPROb = true;
                      });
                    }

                    if (isPROa && !isPROb) return 1;
                    if (isPROb && !isPROa) return -1;

                    return 0;
                  });
                }
              });

              _featuredTemplates = featured;
              AssetStore.emitChange();
            })
            .catch((e) => console.log(e));
        }
        if (_featuredBackgrounds === null) {
          fetch(
            `${process.env.REACT_APP_SERVER_API}/pizapAsset/featuredBackground`,
            {
              method: "get",
              headers: new Headers({
                Authorization: "Bearer " + UserStore.getAccessToken(),
                "Content-Type": "application/json",
              }),
            }
          )
            .then(function (res) {
              return res.json();
            })
            .then(function (featured) {
              _graphicScrollReset = true;
              featured.forEach((category) => {
                if (category.pizapAssets) {
                  category.pizapAssets.sort(function (a, b) {
                    var isPROa = false;
                    if (a.tags) {
                      a.tags.forEach((tag) => {
                        if (tag === "pizapPRO") isPROa = true;
                      });
                    }

                    var isPROb = false;
                    if (b.tags) {
                      b.tags.forEach((tag) => {
                        if (tag === "pizapPRO") isPROb = true;
                      });
                    }
                    if (isPROa && !isPROb) return 1;
                    if (isPROb && !isPROa) return -1;
                    return 0;
                  });
                }
              });
              _featuredBackgrounds = featured;
              AssetStore.emitChange();
            })
            .catch((e) => console.log(e));
        }
        if (_featuredBackgroundsMeme === null) {
          fetch(
            `${process.env.REACT_APP_SERVER_API}/pizapAsset/featuredBackground?app=meme`,
            {
              method: "get",
              headers: new Headers({
                Authorization: "Bearer " + UserStore.getAccessToken(),
                "Content-Type": "application/json",
              }),
            }
          )
            .then(function (res) {
              return res.json();
            })
            .then(function (featured) {
              _graphicScrollReset = true;
              featured.forEach((category) => {
                if (category.pizapAssets) {
                  category.pizapAssets.sort(function (a, b) {
                    var isPROa = false;
                    if (a.tags) {
                      a.tags.forEach((tag) => {
                        if (tag === "pizapPRO") isPROa = true;
                      });
                    }

                    var isPROb = false;
                    if (b.tags) {
                      b.tags.forEach((tag) => {
                        if (tag === "pizapPRO") isPROb = true;
                      });
                    }
                    if (isPROa && !isPROb) return 1;
                    if (isPROb && !isPROa) return -1;
                    return 0;
                  });
                }
              });
              _featuredBackgroundsMeme = featured;
              AssetStore.emitChange();
            })
            .catch((e) => console.log(e));
        }
        if (_featuredBackgroundsEmoji === null) {
          fetch(
            `${process.env.REACT_APP_SERVER_API}/pizapAsset/featuredBackground?app=emoji`,
            {
              method: "get",
              headers: new Headers({
                Authorization: "Bearer " + UserStore.getAccessToken(),
                "Content-Type": "application/json",
              }),
            }
          )
            .then(function (res) {
              return res.json();
            })
            .then(function (featured) {
              _graphicScrollReset = true;
              featured.forEach((category) => {
                if (category.pizapAssets) {
                  category.pizapAssets.sort(function (a, b) {
                    var isPROa = false;
                    if (a.tags) {
                      a.tags.forEach((tag) => {
                        if (tag === "pizapPRO") isPROa = true;
                      });
                    }

                    var isPROb = false;
                    if (b.tags) {
                      b.tags.forEach((tag) => {
                        if (tag === "pizapPRO") isPROb = true;
                      });
                    }
                    if (isPROa && !isPROb) return 1;
                    if (isPROb && !isPROa) return -1;
                    return 0;
                  });
                }
              });
              _featuredBackgroundsEmoji = featured;
              AssetStore.emitChange();
            })
            .catch((e) => console.log(e));
        }
      } catch {
        (e) => {
          console.log(e);
        };
      }

      break;
    case ActionTypes.STICKER_SEARCH:
      _graphicScrollReset = true;
      _selectedItem = null;
      AssetStore.emitChange();
      fetch(
        `${
          process.env.REACT_APP_SERVER_API
        }/pizapAsset/search/${encodeURIComponent(action.data)}`,
        {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + UserStore.getAccessToken(),
            "Content-Type": "application/json",
          }),
        }
      )
        .then(function (res) {
          return res.json();
        })
        .then(function (results) {
          if (results && results.length) {
            const items = results.map((item) => {
              let isPRO = false;
              if (item.tags) {
                item.tags.forEach((tag) => {
                  if (tag === "pizapPRO") isPRO = true;
                });
              }
              return {
                src: item.url,
                icon: item.thumbnail,
                isProOnly: isPRO,
                features: item.features,
              };
            });
            items.sort(function (a, b) {
              if (a.isProOnly && !b.isProOnly) return 1;
              if (b.isProOnly && !a.isProOnly) return -1;

              return 0;
            });
            AssetStore.setSelectedItem({
              Name: action.data,
              items: items,
              isGoogle: false,
            });
          }
          AssetStore.emitChange();
        });
      break;
    case ActionTypes.STICKER_BING_SEARCH:
      _graphicScrollReset = true;
      _isMenuDataLoading = true;
      fetch(
        `${process.env.REACT_APP_BING_IMAGE_SEARCH_API}?q=${action.data}&imageType=Transparent`,
        {
          headers: {
            "Ocp-Apim-Subscription-Key":
              process.env.REACT_APP_BING_SUBSCRIPTION_KEY,
          },
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          _isMenuDataLoading = false;
          if (res) {
            _stickerBingResults = res.value.map((item, idx) => {
              return {
                key: idx,
                src: `${
                  process.env.REACT_APP_SERVER_API
                }/proxy/${item.contentUrl.replace("//", "")}`,
                icon: item.thumbnailUrl,
                originalItem: item,
              };
            });
            AssetStore.setCachedStickers({
              title: action.data,
              activeTab: "bing",
              items: _stickerBingResults,
              isPro: true,
            });
          } else {
            AssetStore.setStartSearchResults({
              activeTab: "templates", 
              subTab: "", 
              results: []
            });
          }
          AssetStore.emitChange();
        })
        .catch((e) => {
          _isMenuDataLoading = false;
          console.log("bing api error.");
          AssetStore.emitChange();
        });

      break;
    case ActionTypes.STICKER_FOLDER_SELECTED:
      _graphicScrollReset = true;
      AssetStore.setSelectedItem(action.item);
      AssetStore.emitChange();
      break;
    case ActionTypes.FONT_CATEGORY_SELECTED:
      AssetStore.setSelectedItem(action.item);
      AssetStore.emitChange();
      break;
    case ActionTypes.LEFT_MENU_SELECTED:
      if (
        AssetStore.getSelectedItem() !== null &&
        AssetStore.getSelectedItem().isGoogle
      ) {
      } else {
        AssetStore.setSelectedItem(null);
      }
      if (action.menu === "sticker") {
        _resetGraphics = true;
      }
      AssetStore.emitChange();
      break;
    case ActionTypes.FILE_PICKER_CATEGORY_SELECTED:
      if (
        action.itemType === "background" ||
        action.itemType === "design" ||
        action.itemType === "collage"
      ) {
        AssetStore.setSelectedItem(action.data);
        AssetStore._filePickerType = action.itemType;
        AssetStore.emitChange();
      }
      break;
    case ActionTypes.LOAD_PRINT_PRODUCTS:
      if (!_printProducts) {
        fetch(`${process.env.REACT_APP_SERVER_API}/print/products`, {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + UserStore.getAccessToken(),
            "Content-Type": "application/json",
          }),
        })
          .then(function (res) {
            return res.json();
          })
          .then(function (results) {
            _printProducts = results;
            AssetStore.emitChange();
          });
      }
      break;
    case ActionTypes.LOAD_GRAPHIC:
      if (_graphics === null) {
        fetch(`${process.env.REACT_APP_SERVER_API}/pizapAsset`, {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + UserStore.getAccessToken(),
            "Content-Type": "application/json",
          }),
        })
          .then(function (res) {
            return res.json();
          })
          .then(function (resJson) {
            _graphics = resJson.data;
            AssetStore.emitChange();
          });
      }
      break;
    case ActionTypes.LOAD_AI_SUGGESTIONS:
      if (_aiSuggestions === null) {
        fetch(
          `${process.env.REACT_APP_SERVER_API}/projectTag/tag/aisuggestion`,
          {
            method: "get",
            headers: new Headers({
              Authorization: "Bearer " + UserStore.getAccessToken(),
              "Content-Type": "application/json",
            }),
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((results) => {
            _aiSuggestions = results;
            AssetStore.emitChange();
          })
          .catch((e) => console.log(e));
      }
      break;
    case ActionTypes.LOAD_AI_STYLES:
      fetch(
        `${process.env.REACT_APP_SERVER_API}/projectTag/tag/${action.data}`,
        {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + UserStore.getAccessToken(),
            "Content-Type": "application/json",
          }),
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((results) => {
          _aiStyles = results;
          AssetStore.emitChange();
        })
        .catch((e) => console.log(e));
      break;
    case ActionTypes.LOAD_TEXT_TEMPLATES:
      if (_textTemplates === null) {
        fetch(
          `${
            process.env.REACT_APP_SERVER_API
          }/projectTag/tag/${encodeURIComponent("text style")}`,
          {
            method: "get",
            headers: new Headers({
              Authorization: "Bearer " + UserStore.getAccessToken(),
              "Content-Type": "application/json",
            }),
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((results) => {
            _textTemplates = results;
            AssetStore.emitChange();
          })
          .catch((e) => console.log(e));
      }
      break;
    case ActionTypes.LOAD_CATEGORIES:
      if (_categories === null) {
        fetch(`${process.env.REACT_APP_SERVER_API}/tagCategory`, {
          method: "get",
          headers: new Headers({
            Authorization: `Bearer ${UserStore.getAccessToken()}`,
            "Content-Type": "application/json",
          }),
        })
          .then((res) => res.json())
          .then((result) => {
            if (result.code === 200) {
              _categories = result.data;
            } else {
              _categories = [];
            }
            AssetStore.emitChange();
          })
          .catch((e) => {
            console.log("e", e);
          });
      }
      break;
    case ActionTypes.LOAD_APPLICATIONS:
      if (_categories === null) {
        fetch(`${process.env.REACT_APP_SERVER_API}/applications`, {
          method: "get",
          headers: new Headers({
            Authorization: `Bearer ${UserStore.getAccessToken()}`,
            "Content-Type": "application/json",
          }),
        })
          .then((res) => res.json())
          .then((result) => {
            const obj = result.reduce(function (acc, cur, i) {
              acc[cur.id] = cur.name;
              return acc;
            }, {});
            _applications = result;
            _applicationsObj = obj;
            AssetStore.emitChange();
          })
          .catch((e) => console.log(e));
      }
      break;
    case ActionTypes.PICKER_SEARCH:
      AssetStore.setPickerSearchResults({
        activeTab: action.data.activeTab,
        catname: action.data.catname,
        results: action.data.results,
      });
      AssetStore.emitChange();
      break;
    case ActionTypes.START_SEARCH:
      AssetStore.setStartSearchResults({
        activeTab: action.data.activeTab,
        subTab: action.data.subTab,
        results: action.data.results,
      });
      AssetStore.emitChange();
      break;
    case ActionTypes.LOAD_AI_TEMPLATES:
      if (_templates === null) {
        fetch(`${process.env.REACT_APP_SERVER_API}/pizapTemplate`, {
          method: "get",
          headers: new Headers({
            Authorization: "Bearer " + UserStore.getAccessToken(),
            "Content-Type": "application/json",
          }),
        })
          .then((results) => {
            _templates = results.data;
            AssetStore.emitChange();
          })
          .catch((e) => console.log(e));
      }
      break;
    default:
      break;
  }
});

export default AssetStore;
