import React, { useMemo, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import AssetStore from "../stores/AssetStore";
import UserActionCreators from "../actions/UserActionCreators";
import Login from "./Login.react";
import UserLeftMenu from "./UserLeftMenu.react";
import Gallery from "react-grid-gallery";
import { Link } from "react-router-dom";
import ThumbOverlay from "./ThumbOverLay.react";
import ProGetSave from "./ProGetSave.react";
import ProGet from "./ProGet.react";
import ProTeaser from "./ProTeaser.react";
import TagDialog from "./TagDialog.react";
import Autosuggest from "react-autosuggest";
import ProCrown from "./ProCrown.react";
import QuickControls from "./QuickControls.react";
import Header from "../layouts/Header";
import { ReactComponent as IconClear } from "../images/editor/clear_icon.svg";
import { ReactComponent as IconSearch } from "../images/editor/search_icon.svg";
import DeleteAccountConfirmModal from "./DeleteAccountConfirmModal";
import EditorStore from "../stores/EditorStore";
import UserStore from "../stores/UserStore";
import { getURLParameter } from "../utils/utils";
import WelcomeProModal from "./WelcomeProModal";
import HelmetDetails from "./HelmetDetails";
import EditorActionCreators from "../actions/EditorActionCreators";
import SkeletonItem from "./SkeletonItem";

function getStateFromStores() {
  return {
    showDeleteAccount: EditorStore.showDeleteAccount(),
    showPro: UserStore.showPro(),
    isPro: UserStore.isPro(),
    showLogin: UserStore.showLogin(),
    projects: UserStore.getProjects(),
    featuredTemplates: AssetStore.getFeaturedTemplates(),
    statusMessage: UserStore.getStatusMessage(),
    showProGet: UserStore.showProGet(),
    showProGetSave: UserStore.showProGetSave(),
    showTagDialog: UserStore.getShowTagDialog(),
    featuredBackgrounds: AssetStore.getFeaturedBackgrounds(),
  };
}

const Start = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(getStateFromStores());
  const [suggestions, setSuggestions] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const isPro = UserStore.isPro();
  const suggestionSelected = useRef(null);
  const [showWelcome, setShowWelcome] = useState(false);
  const refresh = getURLParameter("account");

  const onProjectSelected = (image, event) => {
    const project = data.projects[image];
    const isStringArray = project.tags.every((tag) => typeof tag === "string");
    const tags = isStringArray ? project.tags : project.oldTags;
    if (tags && tags.some((tag) => tag.includes("emoji"))) {
      Lockr.set("featuredApp", "emoji");
    } else if (tags && tags.some((tag) => tag.includes("meme"))) {
      Lockr.set("featuredApp", "meme");
    } else {
      Lockr.set("featuredApp", "pizap");
    }
    navigate("/app/?project=" + project.src);
    return true;
  };

  useEffect(() => {
    if (refresh === "refresh" && data.isPro === true) {
      setShowWelcome(true);
      setTimeout(() => {
        navigate("/app/start");
      }, 5000);
    }
  }, [refresh, data.isPro]);

  // const onCutoutSelected = (image) => {
  //     const cutout = data.cutouts[image];
  //     navigate('/app/?url=' + cutout.src);
  // }

  const onAssetSelected = (image) => {
    const asset = data.assets[image];
    navigate("/app/?url=" + asset.src);
  };

  // const onFeaturedSelected = (image) => {
  //   const asset = data.assets[image];
  //   navigate("/app/?url=" + asset.src);
  // };

  // const handleAddition = (tag) => {
  //   navigate("/app/templates/" + tag.text);
  // };

  const getSuggestions = async (value) => {
    let items = [];
    await fetch(
      `${
        process.env.REACT_APP_SERVER_API
      }/pizapTemplate/searchTag/${encodeURIComponent(value)}`,
      {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + UserStore.getAccessToken(),
          "Content-Type": "application/json",
        }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((results) => {
        if (results.length && results.length > 0)
          items.push({ title: "Templates", items: results });
      });

    let bgs = [];
    data.featuredBackgrounds.map((item) => {
      bgs.push(item.name);
    });
    const resBgs = bgs.filter((item) => {
      return item.toLowerCase().includes(value);
    });
    if (resBgs.length > 0) {
      items.push({ title: "Images & Backgrounds", items: resBgs });
    }
    await fetch(
      `${
        process.env.REACT_APP_SERVER_API
      }/pizapAsset/searchTag/${encodeURIComponent(value)}*`,
      {
        method: "get",
        headers: new Headers({
          Authorization: "Bearer " + UserStore.getAccessToken(),
          "Content-Type": "application/json",
        }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((results) => {
        if (results.length && results.length > 0)
          items.push({ title: "Stickers", items: results });
      });
    return items;
  };

  const onSuggestionsFetchRequested = async ({ value }) => {
    const res = await getSuggestions(value);
    setSuggestions(res);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const handleSearchWvalue = (e) => {
    navigate(`/app/templateSearch/${encodeURI(searchValue)}`, {
      state: {
        activeTab: "templates",
        subTab: "",
      },
    });
  };

  const onSearchClick = (e, suggestion) => {
    suggestionSelected.current = true;
    let _activeTab = "templates";
    let _subTab = "";
  
    if (suggestions[suggestion.sectionIndex].title === "Templates") {
      _activeTab = "templates";
      _subTab = "";
      // Redirect to /app/templateSearch for templates:
      navigate(`/app/templateSearch/${encodeURIComponent(suggestion.suggestion)}`);
      return;
    } else if (
      suggestions[suggestion.sectionIndex].title === "Images & Backgrounds"
    ) {
      _activeTab = "images_backgrounds";
      _subTab = "pizap";
    } else if (suggestions[suggestion.sectionIndex].title === "Stickers") {
      _activeTab = "stickers";
      _subTab = "";
    }
  
    // If it wasn’t Templates, do the normal search route:
    navigate(`/app/templateSearch/${encodeURIComponent(suggestion.suggestion)}`, {
      state: {
        activeTab: _activeTab,
        subTab: _subTab,
      },
    });
  };

  const handleInputChange = (e, { newValue, method }) => {
    setSearchValue(newValue);
  };

  const keyPress = (e) => {
    if (e.keyCode === 13) {
      if (suggestionSelected.current === null) {
        handleSearchWvalue();
      }
    } else {
      suggestionSelected.current = null;
    }
  };

  const getSuggestionValue = function (suggestion) {
    setSearchValue(suggestion);
    return suggestion;
  };

  const _onChange = () => {
    setData(getStateFromStores());
  };

  useEffect(() => {
    let isMounted = true;
    const setCookie = (name, value, days) => {
      const expires = days
        ? `expires=${new Date(
            new Date().getTime() + days * 24 * 60 * 60 * 1000
          ).toUTCString()}`
        : "";
      document.cookie = `${name}=${value}; ${expires}; path=/app/start`;
    };
    if (isMounted) {
      if (document.cookie.indexOf("piZapvisit=true") === -1) {
        setCookie("piZapvisit", true, 30);
        if (!isPro) UserActionCreators.showProGet(true);
      }
      if (UserStore.getUser() === null) {
        UserActionCreators.updateUserData(UserStore.getAccessToken());
      }
      EditorStore.addChangeListener(_onChange);
      UserStore.addChangeListener(_onChange);
      AssetStore.addChangeListener(_onChange);
      EditorActionCreators.SetPickerState(null);
    }
    return () => {
      isMounted = false;
      EditorStore.removeChangeListener(_onChange);
      UserStore.removeChangeListener(_onChange);
      AssetStore.removeChangeListener(_onChange);
    };
  }, []);

  const inputProps = {
    placeholder: "Search",
    value: searchValue,
    onChange: handleInputChange,
    onKeyDown: keyPress,
    type: "text",
  };

  const renderSuggestion = (suggestion) => <div>{suggestion}</div>;

  const renderSectionTitle = (section) => {
    return section.title;
  };

  const getSectionSuggestions = (section) => {
    return section.items;
  };

  const loginDialog = useMemo(() => {
    if (data.showLogin) {
      return <Login />;
    }
    if (data.showProGetSave) {
      return <ProGetSave />;
    }
    if (data.showProGet) {
      return <ProGet />;
    }
    if (data.showPro) {
      return <ProTeaser />;
    }
    if (data.showTagDialog) {
      return <TagDialog />;
    }
  }, [data]);

  const featuredComp = useMemo(() => {
    if (data.featuredTemplates !== null) {
      return data.featuredTemplates.map((c, index) => {
        let sequenceArray = [];
        let createdArray = [];

        c.photoResponses.map((item) => {
          if (item.sequence) {
            sequenceArray.push(item);
          } else {
            createdArray.push(item);
          }
        });

        sequenceArray = sequenceArray.sort((a, b) => {
          return a.sequence - b.sequence;
        });

        createdArray = createdArray.sort((a, b) => {
          return a.created - b.created;
        });
        let catTemplates = [...sequenceArray, ...createdArray]
          .filter((i) => {
            return !i.isTouchup || i.isTouchup === false;
          })
          .map((i, index) => {
            if (!i.oldTags) {
              i.oldTags = i.tags;
            }

            let isFree = true;

            if (
              i.oldTags &&
              i.oldTags.find(
                (element) =>
                  typeof element.toLowerCase === "function" &&
                  element.toLowerCase() === "pizappro"
              ) &&
              !isPro
            ) {
              i.thumbnailCaption = <ProCrown key={i.imageName} />;
              isFree = false;
            }

            if (i.oldTags) {
              i.tags = i.oldTags.map((t) => {
                return { value: t, title: t };
              });
            }
            i.customOverlay = (
              <ThumbOverlay
                key={index}
                project={i}
                hideActions={true}
                isFree={isFree}
              />
            );
            return i;
          });

        return (
          <div className="gallery" key={`gallery-${index}`}>
            <div className="galleryTitle">
              <span>{c.name}</span>
              <Link
                to={`/app/templates/${encodeURIComponent(c.tag)}`}
              >{`See all >`}</Link>
            </div>
            <Gallery
              featureTitle={c.name}
              images={catTemplates}
              allLink={`./app/templates/${encodeURIComponent(c.tag)}`}
              maxRows={1}
              enableImageSelection={false}
              enableLightbox={false}
              onClickThumbnail={onAssetSelected}
              rowHeight={140}
            />
          </div>
        );
      });
    } else {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "60px",
            marginTop: "60px",
          }}
        >
          <SkeletonItem />
          <SkeletonItem />
          <SkeletonItem />
        </div>
      );
    }
  }, [data.featuredTemplates]);

  const projects = useMemo(() => {
    return data.projects.map((item, index) => {
      return {
        ...item,
        customOverlay: <ThumbOverlay key={index} project={item} />,
        thumbnail: item.thumbnail.includes("s3-us-west-1.amazonaws.com/")
          ? (item.thumbnail = item.thumbnail.replace(
              "s3-us-west-1.amazonaws.com/",
              ""
            ))
          : item.thumbnail,
      };
    });
  }, [data.projects]);

  return (
    <div className="startPage">
      <HelmetDetails
        title="piZap - free online photo editor - fun photo effects editor"
        description="piZap Photo Editor is fun and easy to learn online photo editor & collage maker. Tons of effects, fonts, stickers, collage layouts, borders, frames, and editing tools."
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com/app/start"
        keywords=""
      />
      <Header />
      <div className="container" id="container">
        <QuickControls />
        <div className="google-search start-search">
          <Autosuggest
            multiSection={true}
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            onSuggestionSelected={onSearchClick}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            renderSectionTitle={renderSectionTitle}
            getSectionSuggestions={getSectionSuggestions}
            inputProps={inputProps}
          />
          <span className="start__seach__icon">
            <IconSearch />
          </span>
          <span
            className="start__clear__icon"
            onClick={(e) => setSearchValue("")}
          >
            <IconClear />
          </span>
        </div>
        <div className="galleries">
          {projects && projects.length > 0 && (
            <div className="gallery">
              <div className="galleryTitle">
                <span>Recent Projects</span>
                <Link to="/app/projects">{`See all >`}</Link>
              </div>
              <Gallery
                images={projects}
                enableImageSelection={false}
                maxRows={1}
                rowHeight={140}
                enableLightbox={false}
                onClickThumbnail={onProjectSelected}
              />
            </div>
          )}
          {featuredComp}
        </div>
      </div>
      {loginDialog}
      {data.showDeleteAccount ? <DeleteAccountConfirmModal /> : null}
      <UserLeftMenu selected="start" />
      {showWelcome && <WelcomeProModal setShowWelcome={setShowWelcome} />}
    </div>
  );
};

export default Start;
