import React, { useState } from "react";
import SkeletonLoading from "./SkeletonLoadingImage";

const AIStyleItem = (props) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  // Only keep the first two words of the item's caption
  const shortCaption = (props.item.caption || "")
    .split(" ")
    .slice(0, 2)
    .join(" ");

  return (
    <>
      <div
        className="clickable flex-column"
        style={{ alignItems: "center" }}
        onClick={() => props.handleClickStyle(props.item)}
      >
        {!imageLoaded && <SkeletonLoading width="96px" height="96px" />}
        <img
          onLoad={handleImageLoad}
          className={props.currentStyle === props.item ? "selected" : ""}
          src={props.item.thumbnail}
          width={props.currentStyle === props.item ? 86 : 96}
        />
        <span className="style-caption">{shortCaption}</span>
      </div>
    </>
  );
};

export default AIStyleItem;